import { createRouter, createWebHashHistory } from 'vue-router'
import isAuth from '@/utils/isAuth'

const routes = [
    {
        path: '/',
        redirect: '/home/welcome'
    },
    {
        path: '/home',
        component: () => import('../views/HomeView'),
        children: [
            {
                path: 'welcome',
                component: () => import('../views/HomeView/WelcomeView')
            },
            {
                path: 'server_manager',
                component: () => import('../views/HomeView/ServerManagerView')
            },
            {
                path: 'nas_manager',
                component: () => import('../views/HomeView/NasManagerView')
            },
            {
                path: 'server_statistics',
                component: () => import('../views/HomeView/ServerStatisticsView'),
                children: [
                    {
                        path: 'all_inventory',
                        component: () => import('../views/HomeView/ServerStatisticsView/AllInventoryView')
                    },
                    {
                        path: 'repo_inventory',
                        component: () => import('../views/HomeView/ServerStatisticsView/RepoInventoryView')
                    },
                    {
                        path: 'minihome_inventory',
                        component: () => import('../views/HomeView/ServerStatisticsView/MinihomeInventoryView')
                    },
                    {
                        path: 'player_inventory',
                        component: () => import('../views/HomeView/ServerStatisticsView/PlayerInventoryView')
                    }
                ]
            },
        ]
    },
    {
        path: '/login',
        component: () => import('../views/LoginView')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    const authStatus = await isAuth()
    if (to.path === '/login') {
        if (authStatus) {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        if (authStatus) {
            next()
        } else {
            next({ path: '/login' })
        }
    }
})

export default router
